@import '../../../../scss/theme-bootstrap';

.adv_cal_cap {
  font-family: $bbtext-regular;
  .adv-cal-cap-container {
    .loader {
      position: relative;
      min-height: 300px;
    }
    .adv-cal-cap-wrapper {
      .adv-cal-cap-title {
        &__header {
          font-size: 32px;
          text-align: center;
          font-family: $bb-sans;
          margin: 20px 20px 0;
          line-height: 1.3;
          @include breakpoint($medium-up) {
            margin: 35px 20px 0;
          }
        }
      }
      .adv-cal-cap-subtitle {
        &__text {
          font-size: 14px;
          text-align: center;
          margin: 20px;
          line-height: 1.3;
          @include breakpoint($medium-up) {
            margin: 20px 20px 65px;
          }
        }
      }
      .cards {
        position: relative;
        height: 700px;
        @include breakpoint($medium-up) {
          height: 450px;
        }
        @include breakpoint($landscape-up) {
          height: 500px;
        }
        .advent-calendar & {
          display: block;
          margin-#{$ldirection}: 10px;
          @include breakpoint($large-up) {
            margin-#{$ldirection}: 0;
          }
        }
        .card {
          margin: 0;
          .active {
            box-shadow: none;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .card-content {
            height: auto;
            display: block;
            width: 100%;
            position: relative;
            .default-load-txt {
              height: auto;
              position: relative;
              top: auto;
              #{$ldirection}: auto;
              text-align: center;
              color: $color-white;
              .default-txt {
                display: flex;
                flex-direction: column;
                align-items: center;
                .header,
                .subheader,
                .cta {
                  display: inline-block;
                }
                .header {
                  font-size: 5.1vw;
                  line-height: 1;
                  font-family: $bb-sans;
                }
                .subheader {
                  font-size: 1.2vw;
                  line-height: 1.3;
                  margin: 0.4vw;
                }
                .cta {
                  background-color: $color-white;
                  color: $color-black;
                  font-size: 1vw;
                  @include swap_direction(padding, 0 1vw);
                  margin: 1vw;
                  line-height: 2.5;
                }
              }
            }
            .offer-img-bkg {
              display: none;
            }
          }
          &.tout {
            &:nth-child(7n + 4) {
              position: absolute;
              top: 320px;
              #{$ldirection}: 251px;
            }
            &:nth-child(7n - 2) {
              top: 295px;
              #{$ldirection}: 65px;
            }
            &:nth-child(7n - 1) {
              position: absolute;
              top: 424px;
              #{$ldirection}: 65px;
            }
            &:nth-child(7n) {
              position: absolute;
              top: 130px;
              #{$ldirection}: 83px;
            }
            &:nth-child(7n + 1) {
              position: absolute;
              top: 0;
              #{$ldirection}: 285px;
            }
            &:nth-child(7n + 2) {
              position: absolute;
              top: 196px;
              #{$ldirection}: 0;
            }
          }
        }
        @include breakpoint($large-up) {
          height: 550px;
        }
        .card {
          &.tout {
            &:nth-child(7n - 1) {
              @include breakpoint($medium-up) {
                position: absolute;
                max-width: 440px;
                height: 378px;
                top: -20px;
                #{$ldirection}: 318px;
              }
              @include breakpoint($landscape-up) {
                position: absolute;
                max-width: 550px;
                height: 470px;
                top: -20px;
                #{$ldirection}: 445px;
              }
              @include breakpoint($large-up) {
                position: absolute;
                #{$ldirection}: 520px;
                top: -38px;
                max-width: 640px;
                height: 540px;
              }
            }
          }
        }
        .tile {
          &.card:not(.tout) {
            border: none;
            background-repeat: no-repeat;
            box-shadow: none;
            position: absolute;
            &.p1 {
              @include breakpoint($medium-up) {
                height: 115px;
                max-width: 115px;
                width: 100%;
              }
              @include breakpoint($landscape-up) {
                height: 183px;
                max-width: 184px;
                width: 100%;
              }
              @include breakpoint($large-up) {
                position: static;
                height: 203px;
                max-width: 204px;
                width: 100%;
              }
            }
          }
          &.card .active,
          &.card.active:hover,
          &.card.future:hover {
            box-shadow: none;
          }
          video {
            width: 100%;
          }
          background-position: unset;
          &.p2 {
            top: 130px;
            @include breakpoint($medium-up) {
              height: 53px;
              max-width: 67px;
              width: 100%;
              top: 119px;
            }
            @include breakpoint($landscape-up) {
              height: 82px;
              max-width: 100px;
              width: 100%;
              top: 190px;
            }
            @include breakpoint($large-up) {
              top: unset;
              height: 100px;
              max-width: 124px;
              width: 100%;
            }
          }
          &.p3 {
            top: 80px;
            #{$ldirection}: 150px;
            @include breakpoint($medium-up) {
              top: 45px;
              #{$ldirection}: 123px;
              height: 156px;
              max-width: 45px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 60px;
              #{$ldirection}: 200px;
              height: 280px;
              max-width: 64px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: 68px;
              #{$ldirection}: 216px;
              background-size: contain;
              height: 295px;
              max-width: 79px;
              width: 100%;
            }
          }
          &.p4 {
            top: 0;
            #{$ldirection}: 138px;
            @include breakpoint($medium-up) {
              top: 0;
              #{$ldirection}: 123px;
              height: 36px;
              width: 100%;
              max-width: 112px;
            }
            @include breakpoint($landscape-up) {
              top: 0;
              #{$ldirection}: 200px;
              height: 46px;
              width: 100%;
              max-width: 150px;
            }
            @include breakpoint($large-up) {
              top: -10px;
              #{$ldirection}: 215px;
              max-width: 200px;
              height: 62px;
              width: 100%;
            }
          }
          &.p5 {
            top: 50px;
            #{$ldirection}: 220px;
            @include breakpoint($medium-up) {
              top: 42px;
              #{$ldirection}: 190px;
              height: 175px;
              max-width: 45px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 60px;
              #{$ldirection}: 283px;
              height: 315px;
              max-width: 63px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: 68px;
              #{$ldirection}: 330px;
              height: 330px;
              max-width: 78px;
              width: 100%;
            }
          }
          &.p6 {
            top: 270px;
            #{$ldirection}: 180px;
            @include breakpoint($medium-up) {
              top: 0;
              #{$ldirection}: 245px;
              height: 404px;
              max-width: 67px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 0;
              #{$ldirection}: 360px;
              height: 425px;
              max-width: 75px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: -10px;
              #{$ldirection}: 425px;
              height: 538px;
              max-width: 90px;
              width: 100%;
            }
          }
          &.p7 {
            top: 320px;
            #{$ldirection}: 251px;
            @include breakpoint($medium-up) {
              top: 0;
              #{$ldirection}: 320px;
              height: 281px;
              max-width: 88px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 0;
              #{$ldirection}: 448px;
              height: 360px;
              max-width: 116px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: -10px;
              #{$ldirection}: 530px;
              height: 375px;
              max-width: 131px;
              width: 100%;
            }
          }
          &.p8 {
            top: 295px;
            #{$ldirection}: 65px;
            @include breakpoint($medium-up) {
              top: 106px;
              #{$ldirection}: 420px;
              height: 140px;
              max-width: 148px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 135px;
              #{$ldirection}: 576px;
              height: 193px;
              max-width: 194px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: 156px;
              #{$ldirection}: 678px;
              height: 208px;
              max-width: 209px;
              width: 100%;
            }
          }
          &.p9 {
            top: 424px;
            #{$ldirection}: 65px;
            @include breakpoint($medium-up) {
              top: 0;
              #{$ldirection}: 450px;
              height: 94px;
              max-width: 110px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 0;
              #{$ldirection}: 628px;
              height: 121px;
              max-width: 143px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: -10px;
              #{$ldirection}: 728px;
              height: 136px;
              max-width: 158px;
              width: 100%;
            }
          }
          &.p10 {
            top: 130px;
            #{$ldirection}: 83px;
            @include breakpoint($medium-up) {
              top: 82px;
              #{$ldirection}: 572px;
              height: 162px;
              max-width: 61px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 125px;
              #{$ldirection}: 780px;
              height: 233px;
              max-width: 69px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: 115px;
              #{$ldirection}: 900px;
              height: 248px;
              max-width: 84px;
              width: 100%;
            }
          }
          &.p11 {
            top: 0;
            #{$ldirection}: 285px;
            @include breakpoint($medium-up) {
              top: 0;
              #{$ldirection}: 636px;
              height: 285px;
              max-width: 50px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 0;
              #{$ldirection}: 860px;
              height: 420px;
              max-width: 67px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: -10px;
              #{$ldirection}: 1000px;
              height: 458px;
              max-width: 77px;
              width: 100%;
            }
          }
          &.p12 {
            top: 196px;
            #{$ldirection}: 0;
            @include breakpoint($medium-up) {
              top: 0;
              #{$ldirection}: 695px;
              height: 309px;
              max-width: 35px;
              width: 100%;
            }
            @include breakpoint($landscape-up) {
              top: 0;
              #{$ldirection}: 940px;
              height: 500px;
              max-width: 48px;
              width: 100%;
            }
            @include breakpoint($large-up) {
              top: -10px;
              #{$ldirection}: 1090px;
              height: 540px;
              max-width: 55px;
              width: 100%;
            }
          }
        }
        .card:not(.tout) {
          .tile-label {
            height: auto;
            position: relative;
            top: auto;
            #{$ldirection}: auto;
            text-align: center;
            line-height: 4.5;
            color: $color-white;
            font-size: 0.9vw;
            margin-top: 4.7vw;
          }
        }
      }
    }
    .adv-cal-cap-popup {
      position: absolute;
      z-index: 1;
      .popup-screen-wrapper {
        display: none;
      }
      .popup-content-wrapper {
        background-color: $color-white;
        .advent-calendar & {
          width: 90%;
          height: auto;
          box-shadow: none;
          border-radius: 0;
          @include breakpoint($medium-up) {
            width: 45%;
          }
        }
        .offer-details {
          animation: fadeIn linear 0.2s;
          -webkit-animation: fadeIn linear 0.2s;
          &__panel {
            min-height: 550px;
            width: 100%;
            .popup-close {
              top: 30px;
              #{$rdirection}: 30px;
            }
            @include breakpoint($medium-up) {
              position: relative;
              min-height: 250px;
              height: auto;
              padding: 20px;
            }
            @include breakpoint($xlarge-up) {
              padding: 40px;
            }
            .popup-close {
              border-radius: 0;
              transform: initial;
              top: 15px;
              #{$rdirection}: 15px;
              background: $color-white;
              width: 25px;
              height: 20px;
              &:before,
              &:after {
                height: 14px;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .popup-content-wrapper,
      .popup-video-wrapper {
        top: 0;
        #{$ldirection}: 0;
        box-shadow: none;
        border-radius: 0;
        background: transparent;
        transform: none;
        overflow: hidden;
        .offer-details {
          .messages {
            top: auto;
            bottom: 20px;
            font-size: 12px;
            @include breakpoint($medium-up) {
              #{$ldirection}: 33%;
              width: 53%;
            }
            @include breakpoint($large-up) {
              #{$ldirection}: 40%;
            }
          }
        }
        .messages {
          padding: 0;
          z-index: 1;
          background-color: transparent;
          font-weight: normal;
          bottom: 0;
          top: auto;
          .slideInOut {
            transform: translateY(-100%);
            padding: 2vw;
            text-align: center;
            z-index: 1;
            background-color: $color-gray-dark;
            animation: slideInOut linear 3s;
            -webkit-animation: slideInOut linear 3s;
          }
          .message--bottom {
            position: absolute;
            bottom: 2vw;
            #{$rdirection}: 0;
            background: none;
            padding-#{$ldirection}: 0;
            bottom: 6.3vw;
            font-size: 1vw;
            text-align: $ldirection;
            #{$ldirection}: 50%;
            @include breakpoint($large-up) {
              bottom: 8.3vw;
              font-size: 1.56vw;
              text-align: center;
              #{$ldirection}: 0;
            }
          }
        }
      }
      .popup-video-wrapper {
        .video {
          position: relative;
          overflow: hidden;
          width: 100%;
          margin: 0;
          pointer-events: none;
        }
        video::-webkit-media-controls {
          display: none !important;
        }
        .view-offer {
          display: none;
        }
      }
      .offer-details {
        &__img {
          width: 80%;
          margin: 10%;
          text-align: center;
          img {
            height: 170px;
          }
          @include breakpoint($medium-up) {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            #{$ldirection}: 0;
            width: 43%;
            height: 100%;
            padding: 20px;
            text-align: center;
            margin: 0;
            .advent-calendar & {
              float: none;
            }
            img {
              height: auto;
            }
          }
        }
        &__content {
          @include breakpoint($medium-up) {
            .advent-calendar & {
              float: none;
            }
          }
        }
        &__controls {
          @include breakpoint($medium-up) {
            .advent-calendar & {
              float: none;
              margin-bottom: 30px;
            }
          }
        }
        .messages {
          margin-#{$ldirection}: 20px;
        }
        &__content,
        &__controls {
          float: none;
          display: block;
          position: relative;
        }
        &__content {
          width: 48%;
          #{$ldirection}: 43%;
          padding-bottom: 20px;
          &__subtitle {
            font-weight: bold;
            line-height: 2;
          }
          &__title {
            font-family: $bb-sans;
            font-size: 32px;
            line-height: 1;
            margin: 0 0 20px;
          }
          &__description {
            font-size: 1.3vw;
            line-height: 1.3;
            margin-top: 0;
          }
        }
        &__controls {
          #{$ldirection}: 43%;
          .button {
            font-size: 1.82vw;
            @include swap_direction(padding, 0.75em 1em);
            @include breakpoint($large-up) {
              font-size: 1vw;
              @include swap_direction(padding, 1vw 2.3vw);
            }
          }
        }
        &__panel {
          display: block;
          @include breakpoint($large-up) {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

.gift-section {
  background: url('https://m.bobbibrowncosmetics.ca/media/export/cms/holiday21/FH21-AdventCal-Gift_bg.jpg');
  .carousel-dots {
    .slick-dots {
      bottom: -5px;
    }
  }
  width: 100%;
  @include breakpoint($large-up) {
    width: 100%;
    margin: 0 auto;
  }
  .basic-responsive {
    text-align: center;
    .gift-section {
      &__header {
        font-size: 9vw;
        padding: 0 30px;
        line-height: 1.1;
        color: $color-white;
        font-family: $bb-sans;
        @include swap_direction(margin, 20px auto);
        @include breakpoint($medium-up) {
          font-size: 5vw;
        }
        @include breakpoint($large-up) {
          font-size: 2.54vw;
          padding: 0;
          line-height: 0.5;
        }
      }
      &__content {
        width: 100%;
        line-height: 1.5;
        font-size: 3.7vw;
        padding: 20px 0 0;
        font-family: $bbtext-regular;
        @include breakpoint($medium-up) {
          font-size: 2vw;
          width: 50%;
          margin: 0 auto;
          line-height: 1.3;
        }
        @include breakpoint($large-up) {
          font-size: 0.9vw;
          width: 32%;
          margin: 0 auto;
          line-height: 1.2;
        }
      }
    }
  }
  .content-block {
    picture {
      img {
        width: 90%;
        margin-#{$ldirection}: 5%;
        @include breakpoint($large-up) {
          width: 100%;
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
  .node-elc-nodeblock .content {
    margin: 0;
    @include breakpoint($large-up) {
      @include swap_direction(margin, 0 10%);
    }
  }
  .basic-tout-content-block {
    width: 100%;
    float: #{$ldirection};
    .padding--bare {
      @include swap_direction(padding, 0 1rem);
      @include breakpoint($large-up) {
        @include swap_direction(padding, 2rem 0.5rem);
      }
      .content-block__line--content {
        .style--bare {
          font-size: 4.17vw;
          color: $color-white;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            font-size: 3.17vw;
            line-height: 1;
          }
          @include breakpoint($landscape-up) {
            font-size: 2.1vw;
            line-height: 1;
          }
          @include breakpoint($large-up) {
            font-size: 1.17vw;
          }
          .gift-section__livechat,
          .gift-section__freeshipping {
            div {
              font-size: 3.7vw;
              font-family: $bbtext-regular;
              text-transform: none;
              margin: 15px 30px;
              line-height: 1.5;
              @include breakpoint($medium-up) {
                font-size: 2vw;
              }
              @include breakpoint($landscape-up) {
                font-size: 1.2vw;
                margin: 5px auto;
                width: 70%;
                line-height: 1.2;
              }
              @include breakpoint($large-up) {
                font-size: 0.75vw;
                margin: 5px auto;
                width: 70%;
                line-height: 1.2;
              }
            }
            a {
              text-decoration: underline;
              font-size: 3.7vw;
              color: $color-white;
              text-transform: none;
              letter-spacing: 1px;
              display: inline-block;
              margin-bottom: 15px;
              @include breakpoint($medium-up) {
                font-size: 2vw;
              }
              @include breakpoint($landscape-up) {
                font-size: 1.8vw;
                margin-bottom: 0;
              }
              @include breakpoint($large-up) {
                font-size: 0.98vw;
              }
            }
          }
        }
      }
    }
  }
  .contextual-links-region {
    margin: 0 auto;
    width: 80%;
    @include breakpoint($medium-up) {
      width: 100%;
    }
    @include breakpoint($large-up) {
      .content {
        margin: 0;
      }
    }
  }
}

.email-signup {
  width: 100%;
  padding: 10px;
  @include breakpoint($large-up) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .sign-in-component {
    &__birthday-program {
      display: none;
    }
  }
  .site-email-signup {
    &__title {
      font-size: 4.4vw;
      font-family: $bb-sans;
      @include swap_direction(padding, 15px 0);
      @include breakpoint($medium-up) {
        font-size: 3.4vw;
      }
    }
    &__header {
      padding-bottom: 15px;
      font-family: $bbtext-regular;
      font-size: 3.5vw;
      color: $color-gray-darker;
      @include breakpoint($medium-up) {
        font-size: 1.9vw;
      }
      @include breakpoint($landscape-up) {
        font-size: 1.7vw;
      }
    }
    &__form {
      width: 100%;
      .optional-info {
        &__header {
          display: none;
        }
      }
      .site-email-signup {
        &__field {
          width: 100%;
          border: 1px solid $color-gray-darker;
          margin-bottom: 15px;
        }
      }
      .site-email-signup {
        &__submit {
          width: 100%;
          @include swap_direction(margin, 15px 0);
        }
      }
    }
    .site-email-signup__notice,
    .terms-link {
      font-family: $bbtext-regular;
      font-size: 2.8vw;
      color: $color-gray-darker;
      @include breakpoint($medium-up) {
        font-size: 1.8vw;
      }
    }
    @include breakpoint($large-up) {
      position: relative;
      .site-email-signup {
        &__title {
          width: 40%;
          @include swap_direction(padding, 30px 0 5px 20px);
          font-size: 1.4vw;
          line-height: 1;
        }
        &__header {
          width: 25%;
          @include swap_direction(padding, 0 20px 20px);
          font-size: 0.8vw;
          line-height: 1.4;
        }
        &__form {
          width: 40%;
          margin-bottom: 0;
          position: absolute;
          top: 20px;
          #{$ldirection}: 40%;
          .site-email-signup {
            &__field {
              width: 100%;
              border: none;
              border-bottom: 1px solid $color-gray-darker;
              @include swap_direction(padding, 5px 0);
              margin-bottom: 0;
            }
          }
          .site-email-signup {
            &__submit {
              position: absolute;
              top: 0;
              #{$rdirection}: 0;
              color: $color-gray-darker;
              background: $color-white;
              margin: 0;
              width: auto;
            }
          }
        }
      }
      .site-email-signup__notice,
      .terms-link {
        font-size: 0.7vw;
      }
    }
  }
}

.device-mobile {
  .adv_cal_cap {
    font-family: $bbtext-regular;
    .adv-cal-cap-container {
      .adv-cal-cap-wrapper {
        .cards {
          .card:not(.tout) {
            .tile-label {
              margin: 0;
              color: $color-black;
              position: absolute;
              top: 0;
              #{$ldirection}: 0;
              height: 10vw;
              font-size: 4vw;
              line-height: 2.5;
              font-weight: bold;
              margin-top: 3vw;
            }
          }
          .card {
            &.active {
              background-position: bottom 20vw center;
            }
            .card-content {
              height: 100%;
              .default-load-txt {
                color: $color-black;
                height: 100%;
                .default-txt {
                  height: 100%;
                  .header {
                    font-size: 11vw;
                    line-height: 1;
                  }
                  .subheader {
                    font-size: 4.2vw;
                    line-height: 1.3;
                    margin: 3vw;
                  }
                  .cta {
                    position: absolute;
                    bottom: 3vw;
                    #{$ldirection}: 50%;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    background-color: $color-black;
                    color: $color-white;
                    font-size: 3.75vw;
                    line-height: 3.2;
                    font-weight: bold;
                    @include swap_direction(padding, 0 8vw);
                  }
                }
              }
            }
          }
        }
      }
      .adv-cal-cap-popup {
        .popup-content-wrapper {
          .messages {
            .slideInOut {
              padding: 4vw;
            }
          }
        }
        .popup-video-wrapper {
          .video {
            position: absolute;
            bottom: 0;
            #{$ldirection}: 0;
            padding-bottom: 20vw;
            background-color: $color-white;
          }
          .view-offer {
            display: inline-block;
            position: absolute;
            bottom: 4vw;
            #{$ldirection}: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            background-color: $color-black;
            color: $color-white;
            font-size: 3.75vw;
            line-height: 3.2;
            font-weight: bold;
            @include swap_direction(padding, 0 8vw);
          }
        }
        .offer-details {
          &__content {
            width: 100%;
            position: static;
            bottom: 28vw;
            @include swap_direction(padding, 0 4vw);
            #{$ldirection}: 0;
            margin: 0;
            order: 2;
            text-align: center;
            padding-top: 10px;
            @include breakpoint($large-up) {
              width: 100vw;
            }
            .subtitle {
              font-size: 4vw;
              line-height: 2.5;
              font-weight: bold;
            }
            .title {
              font-size: 11vw;
              line-height: 1;
            }
            .description {
              font-size: 4.2vw;
              line-height: 1.3;
              @include swap_direction(margin, 3vw 0);
            }
          }
          &__img {
            order: 3;
            margin-bottom: 0;
          }
          &__controls {
            position: static;
            #{$ldirection}: 0;
            bottom: 8vw;
            @include breakpoint($large-up) {
              position: absolute;
            }
            .button {
              position: absolute;
              bottom: 12vw;
              #{$ldirection}: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              background-color: $color-black;
              color: $color-white;
              font-size: 3.75vw;
              line-height: 3.2;
              font-weight: bold;
              @include swap_direction(padding, 0 8vw);
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInOut {
  0% {
    transform: translateY(-100%);
  }
  10%,
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@-moz-keyframes slideInOut {
  0% {
    transform: translateY(-100%);
  }
  10%,
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes slideInOut {
  0% {
    transform: translateY(-100%);
  }
  10%,
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@-o-keyframes slideInOut {
  0% {
    transform: translateY(-100%);
  }
  10%,
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@-ms-keyframes slideInOut {
  0% {
    transform: translateY(-100%);
  }
  10%,
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.advent-calendar__touts {
  .content-block__content-button {
    color: $color-white;
  }
  .content-formatter .content-block__content-text.padding--bare {
    padding: 0;
  }
  .content-formatter__rendered {
    margin: 60px 0;
  }
}
